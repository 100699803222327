
import {defineComponent} from 'vue';
import {IonPage, IonContent, IonSpinner, modalController} from '@ionic/vue';
import { useRouter } from 'vue-router';
import PageHeader from '@/components/page-header.vue'
import {mapState} from "vuex";
import {AppState} from "@/store-management/vuex";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    PageHeader,
    IonSpinner
  },

  props: {
    padding: {
      type: Boolean,
      default: false
    },
    noscroll: {
      type: Boolean,
      default: false
    },
    gradientBackground: {
      type: Boolean,
      default: false,
    }
  },

  setup() {
    // close modals on route change
    const router = useRouter();
    router.beforeResolve(async () => {
      const isModalOpened = await modalController.getTop();
      if (isModalOpened) {
        await modalController.dismiss();
      }
    })
  },

  computed: {
    ...mapState({
      regionData(state: AppState) {
        return state.region || null;
      },
    }),
    ...mapState(['media']),
  },

  methods: {
    navigate(routerLink: string, reload: boolean): void {
      this.$router.push({
        path: routerLink,
      }).then(() => {
        if (reload) {
          this.$router.go(0);
        }
      });
    }
  }
})
