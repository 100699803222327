<template>
  <basic-page-layout :padding="true">
    <div v-if="regionData && regionData['epapp:dataPrivacy']" class="rich-text-wrapper">
      <div v-html="regionData['epapp:dataPrivacy']"></div>
    </div>
  </basic-page-layout>
</template>

<script lang="ts">
import BasicPageLayout from '@/views/basic-page-layout.vue'

export default {
  extends: BasicPageLayout,

  name: 'PrivacyView',
  components: {
    BasicPageLayout
  }
}
</script>

<style scoped lang="scss">
</style>
