<template>
  <ion-header :translucent="true" class="default-page-header">
    <ion-toolbar>
      <ion-title slot="start" class="header-logo">
        <div class="logo-wrapper" @click="$emit('homeaction', homeLink, true)" role="link" :aria-label="$t('header.logoLabel')">
          <img alt="logo" height="40" :src="logoSrc">
        </div>
      </ion-title>
      <ion-buttons slot="end" class="ion-margin-end">
        <ion-menu-button color="primary" @click="dismissModals()" role="button" :aria-label="$t('header.openMenu')"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import { defineComponent } from 'vue';
import { IonButtons, IonHeader, IonMenuButton, IonTitle, IonToolbar, modalController} from "@ionic/vue";
import HeaderLogoSrc from '@/assets/images/thueringen_entdecken.png';

export default defineComponent({
  name: 'PageHeader',
  components: {
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar
  },

  props: {
    homeLink: {
      type: String,
      default: '#'
    }
  },

  setup() {
    return {
      modalCtrl: modalController,
    }
  },

  methods: {
    async dismissModals() {
      const isModalOpened = await this.modalCtrl.getTop();
      if (isModalOpened) {
        await this.modalCtrl.dismiss();
      }
    }
  },

  data() {
    return {
      logoSrc: HeaderLogoSrc
    }
  }
});

</script>

<style scoped lang="scss">
ion-toolbar{
  background: white;
  --min-height:70px;
}

ion-title {
  padding-left: 16px;

  .logo-wrapper {
    display: flex;
    align-items: center;

    img {
      display: block;
      max-width: unset;
    }
  }
}

.logo-wrapper {
  cursor: pointer;
}

ion-menu-button {
  color: var(--ion-color-primary) ;
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 32px;
  box-shadow: 0 0 6px #43516733 !important;

  &::part(native) {
    padding: 6px;
  }
}

</style>
